import styles from './avp.module.scss';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';
import StickyBar from 'components/StickyBar/StickyBar';
import api from 'api';
import ScheduleSelector, {
  useSchedule,
} from 'components/ScheduleSelector/ScheduleSelector';
import useAuthStore from 'store/useAuth';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Heading from '@wienenergiegithub/ui-next/src/common/components/Heading/Heading';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';

import {
  unstable_useFormState as useFormState,
  unstable_FormLabel as FormLabel,
  unstable_Form as ReakitForm,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';

import { useNotification } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';
import InputField from '@wienenergiegithub/ui-next/src/common/components/InputField/InputField';
import Link from '@wienenergiegithub/ui-next/src/common/components/Link/Link';

const createLaravelFormError = error => {
  const errors = {};
  if (!error?.response?.data) {
    throw errors;
  }
  Object.entries(error.response.data.errors).forEach(([key, val]) => {
    errors[key] = val.join(' ');
  }, {});
  // eslint-disable-next-line no-console
  console.error(errors);

  throw errors;
};

const Demand = ({
  form,
  prefix,
  fields = ['availability', 'bunker_min', 'bunker_max', 'delivery_max'],
  demand,
  userCanEdit,
}) => {
  return (
    <>
      <div className={styles.demandField}>
        <span className={styles.weekday}>{demand.weekday}</span>{' '}
        {demand.shortDate}
      </div>
      {fields.map(field => (
        <InputField
          disabled={!userCanEdit}
          name={[...prefix, field]}
          type="number"
          key={field}
          label={field}
          labelHidden
          {...form}
          className={styles.demandField}
        />
      ))}
    </>
  );
};

const WeekInitial = ({
  form,
  prefix,
  userCanEdit,
  fields = [
    { title: 'Stand (t)', key: 'initial' },
    { title: 'HW (MJ/kg)', key: 'performance' },
  ],
}) => {
  return (
    <>
      {fields.map(field => (
        <div className={styles.initialSettingField} key={field.key}>
          <FormLabel className={styles.initLabel}>{field.title}</FormLabel>
          <InputField
            disabled={!userCanEdit}
            name={[...prefix, field.key, '0', field.key]}
            type="number"
            label={field.key}
            labelHidden
            {...form}
            className={styles.demandField}
            noMessage
          />
          <FormMessage
            {...form}
            name={[...prefix, field.key, '0', field.key]}
            className={styles.errorMessage}
          />
        </div>
      ))}
    </>
  );
};

const Page = () => {
  const notifications = useNotification();
  const schedule = useSchedule();
  const userCan = useAuthStore(state => state.userCan);
  const userCanEdit = userCan('edit avp');
  const [modifiedBy, setModifiedBy] = useState();
  const [updatedAt, setUpdatedAt] = useState();
  const [{ selectedWeek }] = schedule;
  const form = useFormState({
    values: {
      schedule: null,
      facilities: null,
    },
    onValidate: values => {
      const errors = {};
      Object.entries(values.facilities).forEach(([facility]) => {
        if (!values.facilities[facility].initial[0].initial) {
          if (!errors.facilities) {
            errors.facilities = [];
          }
          if (!errors.facilities[facility]) {
            errors.facilities[facility] = {};
          }
          if (!errors.facilities[facility].initial) {
            errors.facilities[facility].initial = [];
          }
          if (!errors.facilities[facility].initial[0]) {
            errors.facilities[facility].initial[0] = {};
          }
          if (!errors.facilities[facility].initial[0].initial) {
            errors.facilities[facility].initial[0].initial = [];
          }
          errors.facilities[facility].initial[0].initial =
            'Feld muss ausgefüllt werden';
        }
        if (!values.facilities[facility].performance[0].performance) {
          if (!errors.facilities) {
            errors.facilities = [];
          }
          if (!errors.facilities[facility]) {
            errors.facilities[facility] = {};
          }
          if (!errors.facilities[facility].performance) {
            errors.facilities[facility].performance = [];
          }
          if (!errors.facilities[facility].performance[0]) {
            errors.facilities[facility].performance[0] = {};
          }
          if (!errors.facilities[facility].performance[0].performance) {
            errors.facilities[facility].performance[0].performance = [];
          }
          errors.facilities[facility].performance[0].performance =
            'Feld muss ausgefüllt werden';
        }
        return errors;
      });
      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async values => {
      const id = (Math.random() + 1).toString(36).substring(7);

      // prepare proper data structure
      const formValues = Object.values(values.facilities)
        .flatMap(itm => {
          return Object.values(itm);
        })
        .map(itm => itm[0]);
      try {
        await api({
          url: `/backend/api/schedules/${selectedWeek.id}/demands`,
          method: 'PUT',
          data: formValues,
        });

        notifications.add({
          title: 'Daten gespeichert',
          icon: 'check',
          id,
          type: 'success',
          handleClose: () => {
            // eslint-disable-next-line no-console
            console.log('close');
          },
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-use-before-define
        getLastEditor();
      } catch (error) {
        notifications.add({
          title: 'Es ist ein Fehler aufgetreten',
          details: 'Bitte versuchen Sie es später erneut',
          icon: 'exclamation',
          id,
          type: 'danger',
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-console
        console.error(error);
        createLaravelFormError(error);
      }
    },
  });

  const getLastEditor = async () => {
    try {
      const {
        data: { data: respDispoSchedule },
      } = await api.get(`/backend/api/schedules/${selectedWeek.value}`);
      setModifiedBy(respDispoSchedule.demand_user.email);

      const date = new Date(respDispoSchedule.demands_updated_at);
      const updateTime = date.toLocaleString('de-AT', {
        dateStyle: 'medium',
        timeStyle: 'short',
      });
      setUpdatedAt(updateTime);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    setModifiedBy(undefined);
    setUpdatedAt(undefined);
    const getDemands = async () => {
      try {
        if (!selectedWeek) {
          return;
        }
        const { data } = await api.get(
          `/backend/api/schedules/${selectedWeek.id}/demands`,
        );
        form.update('facilities', data);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    };
    getDemands();
    getLastEditor();
  }, [selectedWeek]);

  return (
    <Wrapper>
      <Layout title="Anlagenverfügbarkeitsplanung">
        <ReakitForm {...form}>
          <StickyBar>
            <div className={styles.actions}>
              <span className={styles.schedules}>
                <ScheduleSelector scheduleState={schedule} />
              </span>
              {updatedAt && (
                <div className={styles.versionContainer}>
                  {`Version ${updatedAt}`}
                  {modifiedBy && (
                    <Link
                      aria-label="send-email"
                      href={`mailto:${modifiedBy}`}
                      className={styles.link}
                    >
                      {modifiedBy}
                    </Link>
                  )}
                </div>
              )}
              {userCanEdit && (
                <FormSubmitButton
                  {...form}
                  as={Button}
                  className={styles.submit}
                >
                  Speichern
                </FormSubmitButton>
              )}
            </div>
          </StickyBar>
          {form.values.facilities && (
            <Container size="sm">
              <div className={styles.grid}>
                {Object.entries(form.values.facilities).map(([facility]) => {
                  return (
                    <Card className={styles.facility} key={facility}>
                      <Heading size="h6">{facility}</Heading>
                      <div className={styles.demand}>
                        <WeekInitial
                          userCanEdit={userCanEdit}
                          prefix={['facilities', facility]}
                          form={form}
                          key={facility}
                        />
                      </div>
                      <div className={classNames(styles.demand, styles.header)}>
                        <FormLabel
                          className={classNames(
                            styles.demandField,
                            styles.header,
                          )}
                        >
                          Datum
                        </FormLabel>
                        <FormLabel
                          className={classNames(
                            styles.demandField,
                            styles.header,
                          )}
                        >
                          Verf. %
                        </FormLabel>
                        <FormLabel
                          className={classNames(
                            styles.demandField,
                            styles.header,
                          )}
                        >
                          Bunker min
                        </FormLabel>
                        <FormLabel
                          className={classNames(
                            styles.demandField,
                            styles.header,
                          )}
                        >
                          Bunker max
                        </FormLabel>
                        <FormLabel
                          className={classNames(
                            styles.demandField,
                            styles.header,
                          )}
                        >
                          Anl. max
                        </FormLabel>
                      </div>
                      {Object.entries(form.values.facilities[facility])
                        .filter(([key]) => {
                          const disallowedKeys = ['initial', 'performance'];
                          return !disallowedKeys.includes(key);
                        })
                        .map(([day]) => {
                          return (
                            <div className={styles.demand} key={day}>
                              <Demand
                                prefix={['facilities', facility, day, 0]}
                                form={form}
                                userCanEdit={userCanEdit}
                                demand={
                                  form.values.facilities[facility][day][0]
                                }
                              />
                            </div>
                          );
                        })}
                    </Card>
                  );
                })}
              </div>
            </Container>
          )}
        </ReakitForm>
      </Layout>
    </Wrapper>
  );
};

export default Page;
